<template>
  <header>
    <!-- Start Navigation -->
    <nav
        class="navbar navbar-default navbar-sticky bootsnav"
        style="background: #4967AE !important; opacity: 0.92"
    >
      <div class="container py-0">
        <div class="navbar-header">
          <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar-menu"
          >
            <i class="fa fa-bars"></i>
          </button>
          <router-link to="/" class="navbar-brand">
            <img
                src="../../assets/img/logo-white.png"
                class="logo logo-display"
                alt="Logo"
            />
          </router-link>
        </div>
        <!-- End Header Navigation -->

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="navbar-menu">
          <ul class="nav navbar-nav navbar-right" data-in="#" data-out="#">
            <li class="dropdown">
              <router-link style="color:white;"
                  to="/"
                  class="dropdown-toggle active"
                  data-toggle="dropdown"
              >Inicio
              </router-link
              >
            </li>
            <li>
              <router-link :to="{ name: 'Calculator' }" style="color:white;"
              >Calculadora Finiquito
              </router-link
              >
            </li>
            <!-- <pre>{{ getProductsActive }}</pre> -->

            <li class="dropdown">
              <a class="dropdown-toggle" data-toggle="dropdown" style="color:white;">Noticias</a>
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="{ name: 'NotasRelevantes' }" style="color:white;"
                  >Notas Relevantes
                  </router-link
                  >
                </li>
                <li>
                  <a style="color:white;"
                     target="_blank"
                     href="https://www.enfoquelaboral.mx/files/enfoquelaboral/CRITERIOS_JURISPRUDENCIALES.pdf"
                  >Actualizacion de Jurisprudencial</a
                  >
                </li>
              </ul>
            </li>
            <li class="dropdown">
              <a class="dropdown-toggle" data-toggle="dropdown" style="color:white;">Servicios</a>
              <ul class="dropdown-menu">
                <li v-for="(product, index) in getProductsActive" :key="index">
                  <router-link
                      :to="{
                      name: 'Service',
                      params: { slug: product.slug },
                    }"
                  >{{ product.name }}
                  </router-link
                  >
                </li>
              </ul>
            </li>
            <li>
              <a href="https://bolsa.enfoquelaboral.mx/careers/enfoque_laboral" style="color:white;"
              >Bolsa de Trabajo</a
              >
            </li>
            <li class="dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" style="color:white;"
              >Nosotros</a
              >
              <ul class="dropdown-menu">
                <li>
                  <router-link :to="{ name: 'Us' }"
                  >Enfoque Laboral
                  </router-link
                  >
                </li>
                <li>
                  <router-link :to="{ name: 'Contact' }">Contacto</router-link>
                </li>

              </ul>
            </li>
            <li class="dropdown">
              <a style="color:white;" v-if="getUser"  @click="goDashboard"
                           class="dropdown-toggle active"
                           data-toggle="dropdown"
              >Perfil
              </a>
              <router-link style="color:white;" v-else
                           :to="{ name: 'Login' }"
                           class="dropdown-toggle active"
                           data-toggle="dropdown"
              >Ingresar
              </router-link>
            </li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
    </nav>
    <!-- End Navigation -->
  </header>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters("product", ["getProductsActive"]),
    ...mapGetters("auth", ["getUser"]),
  },
  created() {
    console.log(this.getProductsActive)
  },
  methods: {
    goDashboard() {
      window.location.href = "/dashboard";
    },
  },
};
</script>

<style scoped>
li {
  color: white;
}

header {
  position: sticky;
  z-index: 10;
  top: 0;
}
nav.navbar.bootsnav ul.navbar-right li.dropdown ul.dropdown-menu li a {
  border-bottom: medium none;
  color: #333333 !important;
  display: block;
  font-size: 12px;
  margin-bottom: 0;
  padding: 12px 15px;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}
</style>
