<template>
  <footer class="default-padding-top text-light">
    <div class="container">
      <div class="row">
        <div class="f-items fv1">
          <div class="col-xs-12 col-md-4 item">
            <div class="f-item">
              <h4>Enfoque Laboral</h4>
              <p>
                Para más información llama o escríbenos, estamos para
                ayudarte.<br />
                contacto@enfoquelaboral.mx<br />
                Tel: 33 2001 4480<br />
              </p>
              <p>
                <span
                  >Ingresa tu correo para obtener noticias y actualizaciones
                  laborales</span
                >
              </p>
              <div class="subscribe">
                <form action="#">
                  <div class="input-group stylish-input-group">
                    <input
                      type="email"
                      name="email"
                      v-model.trim="email"
                      class="form-control"
                      placeholder="Ingresa tu Email"
                      required
                    />
                    <span class="input-group-addon">
                      <button type="submit" @click="send">
                        <i class="fa fa-paper-plane"></i>
                      </button>
                    </span>
                  </div>
                </form>
                En Enfoque Laboral no compartimos tus datos personales.
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 item">
            <div class="f-item link full">
              <h4>El Sitio</h4>
              <ul>
                <li v-for="(link, index) in links" :key="index">
                  <router-link :to="{ name: link.redirecTo }"
                    >{{ link.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 item">
            <div class="f-item">
              <h4>Twitter Widget</h4>
              <div
                v-for="(t, index) in twitters"
                :key="index"
                class="twitter-item"
              >
                <div class="twitter-content">
                  <p>
                    {{ t.text
                    }}<a target="_blank" :href="t.redirecTo">Ver Noticia</a>
                  </p>
                </div>
                <div class="twitter-context">
                  <i class="fab fa-twitter"></i
                  ><span class="twitter-date">{{ t.date }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Start Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="col-md-6 logo">
              <img src="../../assets/img/logo-simple.png" alt="Logo" />
            </div>
            <div class="col-md-6 footer-bottom-menu text-right">
              <ul>
                <li>
                  <a href="#"
                    >Enfoque Laboral® Todos los Derechos Reservados 2022 | v1.0
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Footer Bottom -->
  </footer>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      links: [
        { name: "Afíliate a nosotros", redirecTo: "Contact" },
        { name: "Quiénes Somos", redirecTo: "Us" },
        { name: "Cálculo de Finiquito", redirecTo: "Calculator" },
        { name: "Preguntas Frecuentes", redirecTo: "" },
        { name: "Aviso de Privacidad", redirecTo: "" },
        { name: "Ayuda", redirecTo: "" },
      ],
      twitters: [
        {
          id: 1,
          text: "El pasado lunes 11 de enero del 2021, se decretó una reforma al artículo 311 en materia del Teletrabajo.",
          date: "2 Enero 2021",
          redirecTo:
            "https://twitter.com/enfoquelaboralm/status/1349110456829566977",
        },
        {
          id: 2,
          text: "Conforme a la Ley Federal del Trabajo artículo 15-A los empleados de las empresas contratantes gozan de todos sus derechos laborales y es la compañía subcontratada, la responsable ante los trabajadores.",
          date: " 11 Enero 2021",
          redirecTo:
            "https://twitter.com/enfoquelaboralm/status/1349110456829566977",
        },
      ],
    };
  },
  methods: {
    send() {
      if (!this.email) {
        return;
      }
      console.log(this.email);
    },
  },
};
</script>
