<template>
  <div class="top-bar-area bg-theme text-light">
    <div class="container">
      <div class="row justify-space-between">
        <div class="col-lg-8 hidden-md-and-down">
          <div class="info box justify-center justify-lg-start d-flex">
            <ul class="">
              <li v-for="(info, index) in myInfo" :key="index">
                <div class="icon">
                  <i :class="info.icon"></i>
                </div>
                <div class="info">
                  <p>{{ info.text }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="topbar-social block col-md-4">
          <ul class="text-right d-flex align-center px-0 float-right">
            <li v-if="getUser">
              <span class="user__name white--text" @click="goDashboard">
                {{ getUser.first_name }} {{ getUser.last_name }}
              </span>
            </li>
            <li v-else>
              <router-link :to="{ name: 'Login' }" style="color: white">
                <span><i class="far fa-user white--text"></i></span>
              </router-link>
            </li>
            <li v-for="(link, index) in links" :key="index">
              <a :href="link.redirecTo" target="_blank"
                ><i :class="link.icon"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      myInfo: [
        {
          icon: "fas fa-map-marker-alt",
          text: "Av. Moctezuma 342 Zapopan Jal.",
        },
        { icon: "fas fa-envelope-open", text: "hello@enfoquelaboral.mx" },
        { icon: "fas fa-mobile-alt", text: "33 3121 2647" },
      ],
      links: [
        {
          icon: "fab fa-facebook-f",
          redirecTo: "https://facebook.com/enfoqueaboralmx/",
        },
        {
          icon: "fab fa-twitter",
          redirecTo: "https://twitter.com/enfoquelaboralm",
        },
        {
          icon: "fab fa-instagram",
          redirecTo: "https://www.instagram.com/enfoquelaboralmx/",
        },
      ],
    };
  },
  created() {

  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
  methods: {
    goDashboard() {
      window.location.href = "/dashboard";
    },
  },
};
</script>

<style scoped>
.top-bar-area .info,
.top-bar-area .info ul,
.top-bar-area .info li p {
  background-color: #131515 !important;
}

.top-bar-area .topbar-social li a {
  color: #ffffff;
}
.user__name:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
