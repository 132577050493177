<template>
  <v-app>
    <v-overlay :value="getOver">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <TheTopBar />
    <TheHeader />
    <div>
      <slot />
    </div>
    <TheFooter />
  </v-app>
</template>

<script >
import TheHeader from "../components/shared/TheHeader.vue";
import TheTopBar from "../components/shared/TheTopBar.vue";
import TheFooter from "../components/shared/TheFooter.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { TheFooter, TheTopBar, TheHeader },
  name: "WebLayout",
  created() {
    this.setOver(false);
  },
  computed: {
    ...mapGetters(["getOver"]),
  },
  methods: {
    ...mapActions(["setOver"]),
  },
};
</script>

<style>
@import url("../assets/css/plugins.min.css");
@import url("../assets/css/style.css");
/* @import url("../assets/css/flaticon-business-set.css"); */
/* @import url("../assets/css/style.css"); */
.overlay {
  position: fixed;
  top: 0;
  z-index: 100;

  width: 100%;
  height: 100%;
  display: none !important;
  background: rgba(0, 0, 0, 0.3);
}
.video-overlay {
  display: none;
}

.loader-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme--light.v-application {
  background: transparent !important;
}

.v-application .title {
  font-size: auto !important;
}

.v-application .black {
  background-color: transparent !important;
  border-color: transparent !important;
}
/* .loader {
  width: 100px;
  height: 100px;
}

.overlay {
  position: fixed;
  top: 0;
  z-index: 100;

  width: 100%;
  height: 100%;
  display: none !important;
  background: rgba(0, 0, 0, 0.3);
}

.loader-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */

input,
select {
  border-radius: 0px !important;
  height: 50px !important;
  padding: 6px 12px;
  font-size: 14px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.owl-carousel {
  display: block !important;
}
.v-application {
  font-family: "Poppins", sans-serif !important;
}

.nav.navbar.bootsnav ul.nav > li > a:hover {
  z-index: 10 !important;
}

/* li.dropdownli.dropdown:hover {
  background-color: rebeccapurple !important;
} */
</style>
